@use './branding/Branding.scss' as b;

html {
	@media (max-width: 499px) {
		font-size: 70%;
	}
	@media (min-width: 500px) {
		font-size: 75%;
	}
	@media (min-width: 800px) {
		font-size: 85%;
	}
	@media (min-width: 1000px) {
		font-size: 85%;
	}
	@media (min-width: 1150px) {
		font-size: 92.5%;
	}
	@media (min-width: 1300px) {
		font-size: 100%;
	}
	@media (min-width: 1470px) {
		font-size: 120%;
	}
	body {
		min-width: 300px;
		max-width: 100vw;
		min-height: 100vh;
		color: b.$body-color;
		background-color: b.$body-background;
		// font-family: b.$body-font-family;
		position: relative;
		overflow-x: hidden;

		// * {
		// 	font-family: b.$body-font-family !important;
		// }

		@media (max-width: 1299px) {
			input:not([type='checkbox']),
			.MuiSelect-select {
				font-size: 16px !important;
			}
		}

		a,
		input {
			outline: none !important;
			&:hover,
			&:focus,
			&:visited {
				text-decoration: none !important;
				box-shadow: none !important;
			}
		}
		a:not(.btn) {
			color: b.$a-color;
			text-decoration: none;
			&:hover {
				color: darken(b.$a-color, 5%);
			}
			&.disabled,
			&:disabled {
				color: lighten(b.$a-color, 10%);
			}
		}
		button {
			background-color: transparent;
			border-color: transparent;
			padding: 0px;
			cursor: pointer;
			&:focus {
				box-shadow: none !important;
				outline: none !important;
			}
		}
		header {
			width: 100%;
		}
		p {
			margin: 0px;
		}
		label {
			color: #aaaaaa;
			margin-bottom: auto;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0px;
			padding: 0px;
		}
		*:disabled,
		*.disabled {
			label {
				color: hinerit;
			}
		}

		.MuiCircularProgress-root {
			color: #ffffff;
		}

		& > #root {
			display: grid;
			grid-template-rows: 7.188rem auto;
			grid-template-areas: 'header' 'main';
			width: 100vw;
			min-height: 100vh;
			& > header {
				grid-area: header;
				display: grid;
				background-color: b.$header-background;
				color: b.$header-color;
				grid-template-areas: '. navbar .';
				nav {
					grid-area: navbar;
					position: relative;
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: space-between;
					.navbar-brand {
						#logo {
							height: 3rem;
						}
					}
					.nav {
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 0px;
						list-style: none;
						margin: 0px;
						padding: 0px;
						.languages {
							button {
								padding: 0px 0.75rem;
								font-size: 1rem;
								color: b.$languages-color;
							}
							&.selected,
							&:hover {
								button {
									color: b.$languages-selected-color;
								}
							}
						}
					}
				}
			}
			& > main {
				grid-area: main;
				display: grid;
				max-width: 100vw;
				h2,
				h1 {
					display: block;
					font-size: 1.93rem;
					line-height: 1.5rem;
					// font-family: b.$title-font-family;
					vertical-align: middle;
				}
				h2 {
					font-weight: 400;
				}
				h1 {
					font-weight: 800;
				}

				&:not(.home) {
					grid-template-areas: '. . .' '. banner .' '. center .' '. . .' '. . .';
					form,
					#message,
					#container-loader {
						grid-area: center;
						place-self: center center;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 2rem;
					}
					#banner-container {
						grid-area: banner;
						text-align: center;
						margin-bottom: 3rem;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					form {
						width: 100%;
						max-width: 26.5rem;
						place-self: start center;
						gap: 1.2rem;
						.o7-checkbox .checkboxos-label,
						p {
							font-size: 1.3rem;
						}
					}
				}

				.message {
					grid-area: center;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.59rem;
					line-height: 2.4rem;
					font-weight: 400;
				}
			}

			@media (max-width: 799px) {
				& > header,
				& > main:not(.home) {
					grid-template-columns: 2.125rem auto 2.125rem;
				}
				& > main:not(.home) {
					grid-template-rows: 1.813rem max-content auto 1.813rem 7.188rem;
				}
			}
			@media (min-width: 800px) {
				& > header,
				& > main:not(.home) {
					grid-template-columns: 3.125rem auto 3.125rem;
				}
				& > main:not(.home) {
					grid-template-rows: 2.813rem max-content auto 2.813rem 7.188rem;
				}
			}
			@media (min-width: 1000px) {
				& > header,
				& > main:not(.home) {
					grid-template-columns: 4.575rem auto 4.575rem;
				}
			}
			@media (min-width: 1200px) {
				& > header,
				& > main:not(.home) {
					grid-template-columns: 6.875rem auto 6.875rem;
				}
			}
		}
	}
}
