/* colors */

$highlight: #00dbcd;
$main: #ffffff;
$secondary1: #818990;
$secondary2: #0b1115;
$background: #1f2428;
$widgets: #2b3238;
$cover1: #1f2428;
$cover2: #ffffff;

$font1: 'Montserrat', sans-serif;
$font2: 'Verdana', sans-serif;

/* branding */

$body-background: $widgets;
$body-color: $main;

$header-background: $secondary2;
$header-color: $main;

$btn-background: $highlight;
$btn-border-color: $highlight;

$btn-color: $main;

$btn-disabled-background: $secondary1;
$btn-disabled-border-color: $btn-disabled-background;
$btn-disabled-color: $main;

$btn-form-background: $highlight;
$btn-form-border-color: $btn-form-background;
$btn-form-color: $body-background;

$btn-form-disabled-background: $btn-disabled-background;
$btn-form-disabled-border-color: $btn-form-disabled-background;
$btn-form-disabled-color: $btn-disabled-color;

$btn-link-color: $highlight;
$btn-link-color-disabled: $main;

$download-it-now-color: $body-color;

$input-color: $body-color;
$input-border-color: $cover2;
$input-placeholder-color: $secondary1;

$input-disabled-color: secondary3;
$input-disabled-border-color: secondary3;

$checkbox-background: $highlight;
$checkbox-color: $highlight;

$a-color: $highlight;

html {
	&[lang='en'] {
		* {
			font-family: $font1 !important;
		}

		$body-font-family: $font1;
		$title-font-family: $font1;
	}
	&[lang='el'] {
		* {
			font-family: $font2 !important;
		}

		$body-font-family: $font2;
		$title-font-family: $font2;
	}
}

$languages-color: $main;
$languages-selected-color: $highlight;

$app-name: $highlight;
